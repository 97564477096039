$displayProps: ("block", "inline-block", "inline", "flex", "grid", "inline-flex", "inline-grid", "table", "inline-table", "list-item", "none", "contents");

@each $disp in $displayProps {
  .d-#{$disp} {
    display: #{$disp};
  }
}

$spaceAmounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, auto);

@each $space in $spaceAmounts {
  @if $space==auto {
    .m-#{$space} {
      margin: #{$space} !important;
    }

    .mx-#{$space} {
      margin-left: #{$space} !important;
      margin-right: #{$space} !important;
    }

    .my-#{$space} {
      margin-top: #{$space} !important;
      margin-bottom: #{$space} !important;

    }

    .ml-#{$space} {
      margin-left: #{$space} !important;
    }

    .mr-#{$space} {
      margin-right: #{$space} !important;
    }

    .mb-#{$space} {
      margin-bottom: #{$space} !important;
    }

    .mt-#{$space} {
      margin-top: #{$space} !important;
    }
  }

  @else {
    .m-#{$space} {
      margin: #{$space}rem !important;
    }

    .mx-#{$space} {
      margin-left: #{$space}rem !important;
      margin-right: #{$space}rem !important;
    }

    .my-#{$space} {
      margin-top: #{$space}rem !important;
      margin-bottom: #{$space}rem !important;

    }

    .ml-#{$space} {
      margin-left: #{$space}rem !important;
    }

    .mr-#{$space} {
      margin-right: #{$space}rem !important;
    }

    .mb-#{$space} {
      margin-bottom: #{$space}rem !important;
    }

    .mt-#{$space} {
      margin-top: #{$space}rem !important;
    }

    .p-#{$space} {
      padding: #{$space}rem !important;
    }

    .px-#{$space} {
      padding-left: #{$space}rem !important;
      padding-right: #{$space}rem !important;
    }

    .py-#{$space} {
      padding-top: #{$space}rem !important;
      padding-bottom: #{$space}rem !important;

    }

    .pl-#{$space} {
      padding-left: #{$space}rem !important;
    }

    .pr-#{$space} {
      padding-right: #{$space}rem !important;
    }

    .pb-#{$space} {
      padding-bottom: #{$space}rem !important;
    }

    .pt-#{$space} {
      padding-top: #{$space}rem !important;
    }
  }
}

$textAlignments: ("left", "right", "center", "revert");

@each $textAlign in $textAlignments {
  .text-#{$textAlign} {
    text-align: #{$textAlign};
  }
}