/* You can add global styles to this file, and also import other style files */
@import './variables.scss';
@import './utils.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
}

* {
  outline: none;
}

img {
  width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-container {
  overflow-x: scroll;
}

.page-container {
  width: 800px;
  max-width: 100vw;
  margin: auto;
  padding: 0 0 30px 0;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 1px solid $arancioBrand;
  //box-shadow: 0px 0px 5px rgba(0,0,0,0.2);

  .section-title {
    text-align: center;
    background-color: $arancioBrand;
    margin: 0;
    padding: 10px 0 10px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    font-weight: 500;

    .tooltip {
        fa-icon {
            font-size: 15px;
            position: relative;
            bottom: 5px;
        }
    }

    .small {
        font-size: 10px;
    }
  }
}

.container-large {
  width: 1800px;
  max-width: 95vw;
}

.input-block {
  .clr-control-container {
    width: 100%;

    .clr-input-wrapper {
      max-width: 100%;
    }

    .clr-select-wrapper {
      display: block;

      select {
        width: 100%;
      }
    }

    .clr-input-group {
      display: block;
    }

    .clr-input {
      width: 100%;
    }

    .clr-smart-open-close {
      position: relative;
      right: 26px;
    }

    textarea {
      width: 100%;
    }
  }
}

input.clr-input {
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.clr-input-group {
  input.clr-input {
    border: 1px solid #b3b3b3;
    border-radius: 5px;
  }

  &.clr-focus {
    input.clr-input:focus {
      border: 1px solid #b3b3b3;
    }
  }

}

.clr-select-wrapper select.clr-select {
  border: 1px solid #b3b3b3;
  border-radius: 5px;
}

.clr-checkbox-wrapper.centered {
  justify-content: center;
}

.a-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

button:focus {
  outline: none !important;
}

button.btn.btn-primary, a.btn.btn-primary {
  background-color: $arancioBrand;
  border-color: $arancioBrandDark;

  &:not(.btn-icon) {
    min-width: 110px;
  }

  &:hover {
    background-color: $arancioBrandDark;  
  }

  &:active {
    box-shadow: 0 0.1rem 0 0 $arancioBrand inset;
  }
}

button.btn fa-icon {
  font-size: 15px;
}

input.clr-input:not([readonly]):focus {
  border-bottom-color: $verdeBrandDark;
  background: linear-gradient(to bottom,transparent 95%,$verdeBrandDark 95%) no-repeat;
  background-size: 100% 100%;
}

div.clr-input-group.clr-focus {
  background: linear-gradient(to bottom,transparent 95%,$verdeBrandDark 95%) no-repeat;
  border-bottom-color: $verdeBrandDark;
  background-size: 100% 100%;
}

.modal-body .clr-input-wrapper {
  max-height: 2rem;
}

div.toast-container .ngx-toastr {
  padding: 7px 7px 7px 50px;
  background-size: 18px;
}

.custom-tabs-switch {
  display: flex;
  justify-content: center;
  width: fit-content;
  align-items: center;
  margin: auto;
  border-radius: 8px;
  margin-bottom: 15px;

  p, .tab {
      padding: 5px 13px;
      border: 1px solid $verdeBrand;
      border-left: none;
      cursor: pointer;
      transition: background-color 200ms linear;
      margin: 0;

      fa-icon {
          margin-right: 5px;
      }

      &:hover {
          background-color: #93c7bb;
      }

      &.active {
          background-color: $verdeBrand;
          color: white;
      }

      &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          border-left: 1px solid $verdeBrand;
      }

      &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
      }
  }
}

.table {
  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
}

@media (max-width: 768px) {
  .page-container {
    width: 95%;
  }

  .container-large {
    max-width: 95vw;
  }
}